import React from "react";
import { Route, Link, Switch } from "react-router-dom";

import config from "../../configs/routes";
import "./app.scss";

const App = () => {
  const routeComponents = config.map(({ path, component }, key) => {
    return <Route exact path={path} component={component} key={key} />;
  });

  return (
    <div>
      {/* <header>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/store/2">Route Param</Link>
      </header> */}

      <main>
        <div className='app-container'>
          <Switch>{routeComponents}</Switch>
        </div>
      </main>
    </div>
  );
};

export default App;
