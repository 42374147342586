import React from "react";
import { push } from "connected-react-router";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";

import "./home.scss";

import {
  increment,
  incrementAsync,
  decrement,
  decrementAsync,
} from "../../reducers/counter";

const Home = (props) => (
  <div className='main-container'>
    <div className='avtar-wrapper'>
      <img
        src={`${process.env.PUBLIC_URL}/avtar.svg`}
        alt=''
        className='avtar'
      />
    </div>
    <div className='css-typing'>
      <p>
        Hey, Thank you for stopping by. I am a web developer working with
        Atlassian.
      </p>
      <p>
        Skilled in AngularJs, ReactJs, Typescript, Jquery, HTML, CSS, SCSS.
        Graduated from NIT, Patna.
      </p>
      <p>
        Feel free to check{" "}
        <a href='https://blog.goldi.in/' target='_blank'>
          my blog
        </a>
      </p>
    </div>

    <div className='css-typing-mobile'>
      <p>Hey, I am a web developer working</p>
      <p>with Atlassian. Skilled in ReactJs</p>
      <p>, AngularJs, Typescript, Jquery</p>
      <p>HTML, CSS, SCSS.</p>
      <p>Graduated from NIT, Patna.</p>
      <p>
        check <a href='https://blog.goldi.in/'>my blog</a>
      </p>
    </div>
  </div>
);

const mapStateToProps = ({ counter }) => ({
  count: counter.count,
  isIncrementing: counter.isIncrementing,
  isDecrementing: counter.isDecrementing,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      increment,
      incrementAsync,
      decrement,
      decrementAsync,
      changePage: () => push("/about-us"),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
